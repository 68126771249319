import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { Content } from "../models/contents.entity";


@Injectable()
export class ContentsRepository extends AbstractRepository<Content> {
  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    protected override readonly http: HttpClient
  ) {
    super(http, Content);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "contents";
  }

  findAll(data: any): Observable<Content[]> {
    return this.http
      .post<any>([this.apiUrl, this.getEntityName(), 'all'].join("/"), data)
      .pipe(
        map(({ items, ...rest }) => ({
          ...rest,
          items: items.map((item: any) => this.instantiate(item)),
        }))
      );
  }
}