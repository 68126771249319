<div class="table row">
  <div class="column shrink" *ngIf="select.observed">
    <div class="table-header cell center-children-vertically">
      <app-checkbox [formControl]="allSelectForm"/>
    </div>
    <div *ngFor="let item of items; let index = index">
      <div class="center-children-vertically cell" *ngIf="select.observed">
        <app-checkbox [formControl]="selectionCheckboxes[index].form"/>
      </div>
    </div>
  </div>
  <div class="column grow" *ngFor="let headerColumnName of getColumnName()">
    <div class="row gap center-children-vertically table-header cell" [class.pointer]="getColumnConfig(headerColumnName).sortable" (click)="sortColumn(headerColumnName)">
      <div class="table-header header-column-name ">{{getColumnConfig(headerColumnName).displayName }}</div>
      <div class="column text-small" *ngIf="getColumnConfig(headerColumnName).sortable">
        <app-icon size="12" type="chevron-up" *ngIf="lastSortOption$.value?.sort !== headerColumnName || (lastSortOption$.value?.sort === headerColumnName && lastSortOption$.value?.order !== 'DESC')" />
        <app-icon size="12" type="chevron-down" *ngIf="lastSortOption$.value?.sort !== headerColumnName || (lastSortOption$.value?.sort === headerColumnName && lastSortOption$.value?.order !== 'ASC')"/>
      </div>
    </div>
    <div class="center-children-vertically cell" *ngFor="let item of items; let index = index"
         [style]="getColumnConfig(headerColumnName)?.styleFunction ? getColumnConfig(headerColumnName)?.styleFunction(getItemValue(item, headerColumnName)) : getColumnConfig(headerColumnName).style">
      {{ getColumnConfig(headerColumnName)?.transformFunction ? getColumnConfig(headerColumnName)?.transformFunction(getItemValue(item, headerColumnName), item) : getItemValue(item, headerColumnName) }}
    </div>
  </div>
  <div class="column shrink" *ngIf="goTo.observed">
    <div class="table-header cell "></div>
    <div class="pointer cell center-children-vertically base-padding" *ngFor="let item of items; let index = index" (click)="goToRow(item)">
      <app-icon type="chevron-right"/>
    </div>
  </div>
</div>
