<div class="padding-wrapper column">
    <div class="wrapper">
        <h1 class="margin-bottom">ICI, DÉVELOPPEZ-VOUS EN TOUTE AUTONOMIE</h1>

        <div>
            <div class="tabs">
                <button class="row center-children-vertically gap" (click)="selectTab(1)" [class.active]="selectedTab === 1"><app-icon type="telescope" [color]="selectedTab === 1 ? '#fff' : '#000'" size="22"></app-icon><span>Découvrir et m'inspirer</span></button>
                <button class="row center-children-vertically gap" (click)="selectTab(2)" [class.active]="selectedTab === 2"><app-icon type="diagonal-arrow" [color]="selectedTab === 2 ? '#fff' : '#000'" size="22"></app-icon><span>Être mieux et agir</span></button>
                <button class="row center-children-vertically gap" (click)="selectTab(3)" [class.active]="selectedTab === 3"><app-icon type="heart-hand" [color]="selectedTab === 3 ? '#fff' : '#000'" size="22"></app-icon><span>Me transformer</span></button>
            </div>
        </div>

        <div class="margin-top">
            <h2 class="margin-bottom">FILTRES</h2>
            <form [formGroup]="contentsForm">
                <div  class="container">
                    <div class="item">
                        Format
                        <div class="margin-top margin-left column" formArrayName="formats">
                            <label *ngFor="let format of formats.controls; let i = index" [formGroupName]="i">
                                <input type="checkbox" formControlName="value">
                                {{ format.get('label')?.value }}
                            </label>
                        </div>
                    </div>
                    <div class="item">
                        Durée de lecture
                        <div class="margin-top margin-left column" formArrayName="durations">
                            <label *ngFor="let duration of durations.controls; let i = index" [formGroupName]="i">
                                <input type="checkbox" formControlName="value">
                                {{ duration.get('label')?.value }}
                            </label>
                        </div>
                    </div>
                    <div class="item">
                        Pilier
                        <div class="margin-top margin-left">
                            <div class="pillar-item" (click)="toggleSelection('individu')" [class.selected]="isSelected('individu')"><app-icon type="candle" size="25"></app-icon><div class="pillar-text">Individu</div></div>
                            <div class="pillar-item" (click)="toggleSelection('environnement')" [class.selected]="isSelected('environnement')"><app-icon type="house-rustic" size="25"></app-icon><div class="pillar-text">Environnement</div></div>
                            <div class="pillar-item" (click)="toggleSelection('evenements')" [class.selected]="isSelected('evenements')"><app-icon type="storm" size="25"></app-icon><div class="pillar-text">Événements</div></div>
                        </div>
                    </div>
                    <div class="item">
                        Thématique liée
                        <div class="margin-top margin-left column" formArrayName="themes">
                            <label *ngFor="let theme of themes.controls; let i = index" [formGroupName]="i">
                                <input type="checkbox" formControlName="value">
                                {{ theme.get('label')?.value }}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="items && items.length > 0; else noItemsTemplate">
            <app-media-card *ngFor="let item of items" [content]="item"></app-media-card>
        </div>
        <ng-template #noItemsTemplate>
            <div class="column center-children-vertically no-contents-container">
                <div class="no-contents">
                    <h2>BIENTÔT SUR WE TALK...</h2>
                    <p class="no-content-text margin-top-xl">
                        Nous n’avons pas encore de contenus à vous proposer. N’hésitez pas à nous suggérer votre sujet via le formulaire “Contact” :
                         <strong class="text-primary">nous sommes toujours en préparation de nouveaux contenus !</strong>
                    </p>
                </div>
                <img class="responsive-img" src="assets/images/under-construction.png" alt="construction">
            </div>
        </ng-template>
    </div>
</div>