import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedModule } from "../shared/shared.module";

import { ContentsListComponent } from "./contents-list/contents-list.component";
import { ContentsRepository } from "./repositories/contents.repository";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild([{
                path: "",
                pathMatch: "full",
                component: ContentsListComponent,
                data: {
                  permissions: {
                    only: "admin",
                    redirectTo: "home",
                  },
                },
        }])
    ],
    declarations: [
        ContentsListComponent
    ],
    providers: [
        ContentsRepository
    ]
})

export class ContentsModule { }