import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent {
  @Input() audioSrc!: string;

  @ViewChild('audio') audioElement!: ElementRef<HTMLAudioElement>;

  currentTime: number = 0;

  isMuted: boolean = false;

  isPlaying = false;


  playAudio() {
    this.isPlaying = !this.isPlaying;
    if (this.isPlaying) {
      this.audioElement.nativeElement.play();
    } else {
      this.audioElement.nativeElement.pause();
    }
  }

  pauseAudio() {
    this.audioElement.nativeElement.pause();
  }

  skipBackward() {
    this.audioElement.nativeElement.currentTime -= 10;
  }

  skipBackwardQuarter() {
    const audioDuration = this.audioElement.nativeElement.duration;
    this.audioElement.nativeElement.currentTime -= audioDuration * 0.25;
}

  skipForward() {
    this.audioElement.nativeElement.currentTime += 10;
  }

  skipForwardQuarter() {
    const audioDuration = this.audioElement.nativeElement.duration;
    this.audioElement.nativeElement.currentTime += audioDuration / 4;
  }

  changePlaybackSpeed(event: Event) {
    const speed = (event.target as HTMLSelectElement).value;
    this.audioElement.nativeElement.playbackRate = parseFloat(speed);
  }

  updateProgress() {
    this.currentTime = this.audioElement.nativeElement.currentTime;
  }

  updateRangeBackground() {
    const range = document.querySelector('.custom-range') as HTMLInputElement;
    const value = (range.valueAsNumber - Number(range.min)) / (Number(range.max) - Number(range.min)) * 100;
    range.style.setProperty('--value', `${value}%`);
}

  seekAudio(event: Event) {
    const seekTime = (event.target as HTMLInputElement).value;
    this.audioElement.nativeElement.currentTime = parseFloat(seekTime);
    const input = event.target as HTMLInputElement;
    this.audioElement.nativeElement.currentTime = input.valueAsNumber;
    this.updateRangeBackground();
  }

  toggleMute() {
    this.isMuted = !this.isMuted;
    this.audioElement.nativeElement.muted = this.isMuted;
  }

  changeVolume(event: Event) {
    const volume = (event.target as HTMLInputElement).value;
    this.audioElement.nativeElement.volume = parseFloat(volume);
  }

  updateVolumeIcon() {
    this.isMuted = this.audioElement.nativeElement.muted;
  }

  setVolume(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const volume = parseFloat(inputElement.value);
    if (this.audioElement && this.audioElement.nativeElement) {
        this.audioElement.nativeElement.volume = volume;
    }
}
}