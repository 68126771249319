<div class="padding-wrapper column">
    <div class="wrapper">
        <h1 class="margin-bottom">ICI, DÉVELOPPEZ-VOUS EN TOUTE AUTONOMIE</h1>

        <div>
            <div class="tabs">
                <button class="tab-button row center-children-vertically gap" (click)="selectTab(1)" [class.active]="selectedTab === 1"><app-icon type="telescope" [color]="selectedTab === 1 ? '#fff' : '#000'" size="22"></app-icon><span>Découvrir et m'inspirer</span></button>
                <button class="tab-button row center-children-vertically gap" (click)="selectTab(2)" [class.active]="selectedTab === 2"><app-icon type="diagonal-arrow" [color]="selectedTab === 2 ? '#fff' : '#000'" size="22"></app-icon><span>Être mieux et agir</span></button>
                <button class="tab-button row center-children-vertically gap" (click)="selectTab(3)" [class.active]="selectedTab === 3"><app-icon type="heart-hand" [color]="selectedTab === 3 ? '#fff' : '#000'" size="22"></app-icon><span>Me transformer</span></button>
            </div>
        </div>

        <div class="margin-top">
            <div class="header-inputs row">
                <app-input
                [margin-bottom]="false"
                class="input"
                type="search"
                [placeholder]="
                  'Rechercher une ressource'
                "
                [inputField]="searchForm"
                [gotSubMenu]="true"
                [hasFilters]="false"
                (enterPressed)="handleEnterPressed($event)"
              >
                <div class="filter-menu padding-wrapper">
                    <div class="row">
                        <h2>Filtre ressource</h2>
                      </div>
                      <form class="column gap" [formGroup]="contentsForm">
                        <div class="margin-top">
                            <p><strong>Format</strong></p>
                            <div *ngIf="formats.controls" class="column margin-left" formArrayName="formats">
                                <label *ngFor="let formats of formats.controls; let i = index" [formGroupName]="i">
                                    <input type="checkbox" formControlName="value">
                                    {{ formats.get('name')?.value }}
                                </label>
                            </div>
                        </div>
                        <div class="margin-top">
                            <p><strong>Durée de lecture</strong></p>
                            <div *ngIf="durations.controls" class="column margin-left" formArrayName="durations">
                                <label *ngFor="let duration of durations.controls; let i = index" [formGroupName]="i">
                                    <input type="checkbox" formControlName="value">
                                    {{ duration.get('name')?.value }}
                                </label>
                            </div>
                          </div>
                      </form>
                </div>
                </app-input>
            </div>
            <form class="margin-top margin-bottom-xl" [formGroup]="contentsForm">
                <div  class="container">
                    <div class="item-1">
                        <p><strong>Pilier</strong></p>
                        <div *ngIf="pillars.controls" formArrayName="pillars" class="margin-top margin-left">
                            <div *ngFor="let pillar of pillars.controls; let i = index" [formGroupName]="i" class="pillar-item" (click)="toggleSelection(pillar.value)" [class.selected]="pillar.value.value">
                              <app-icon [type]="pillar.value.icon" size="25"></app-icon>
                              <div class="pillar-text">{{ pillar?.get('name')?.value }}</div>
                            </div>
                          </div>
                    </div>
                    <div class="item-2">
                        <p><strong>Thématique liée</strong></p>
                        <div class="linked-thematic">
                            <div class="themes-container">
                                <div *ngIf="themesPartOne.controls" class="column" formArrayName="themesPartOne">
                                    <label *ngFor="let themeOne of themesPartOne.controls; let i = index" [formGroupName]="i">
                                        <input type="checkbox" formControlName="value">
                                        {{ themeOne?.get('name')?.value }}
                                    </label>
                                </div>
                                <div *ngIf="themesPartTwo.controls" class="column" formArrayName="themesPartTwo">
                                    <label *ngFor="let themeTwo of themesPartTwo.controls; let i = index" [formGroupName]="i">
                                        <input type="checkbox" formControlName="value">
                                        {{ themeTwo.get('name')?.value }}
                                    </label>
                                </div>
                                <div *ngIf="themesPartThree.controls" class="column" formArrayName="themesPartThree">
                                    <label *ngFor="let themeThree of themesPartThree.controls; let i = index" [formGroupName]="i">
                                        <input type="checkbox" formControlName="value">
                                        {{ themeThree.get('name')?.value }}
                                    </label>
                                </div>
                            </div>
                            <a href="#" class="plus-ten" (click)="openMoreThematic($event)" [dropdownTriggerFor]="filterDropdown">+10</a>
                            <app-dropdown [closeOnClick]="false" [isFilter]="true" #filterDropdown class="dropdown-wrapper">
                                <div #ref>
                                    <div class="padding-wrapper">
                                        <div class="row">
                                            <p><strong>Thématique liée</strong></p>
                                        </div>
                                        <div class="row gap margin-left">
                                            <div class="column" formArrayName="themesPartFour">
                                                <label *ngFor="let themeFour of themesPartFour.controls; let i = index" [formGroupName]="i">
                                                    <input type="checkbox" formControlName="value">
                                                    {{ themeFour.get('name')?.value }}
                                                </label>
                                            </div>
                                            <div class="column" formArrayName="themesPartFive">
                                                <label *ngFor="let themeFive of themesPartFive.controls; let i = index" [formGroupName]="i">
                                                    <input type="checkbox" formControlName="value">
                                                    {{ themeFive.get('name')?.value }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="!ref.hasChildNodes()">
                                  <div class="default-filter">
                                    Aucun filtre disponible
                                  </div>
                                </ng-container>
                            </app-dropdown>
                         </div>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="items && items.length > 0; else noItemsTemplate">
            <app-media-card *ngFor="let item of items" [content]="item"></app-media-card>
        </div>
        <ng-template #noItemsTemplate>
            <div class="column center-children-vertically no-contents-container">
                <div class="no-contents">
                    <h2>BIENTÔT SUR WE TALK...</h2>
                    <p class="no-content-text margin-top-xl">
                        Nous n’avons pas encore de contenus à vous proposer. N’hésitez pas à nous suggérer votre sujet via le formulaire “Contact” :
                         <strong class="text-primary">nous sommes toujours en préparation de nouveaux contenus !</strong>
                    </p>
                </div>
                <img class="responsive-img" src="assets/images/under-construction.png" alt="construction">
            </div>
        </ng-template>
    </div>
</div>