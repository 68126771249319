import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { Tag } from "../../../shared/components/tags/tags.component";
import { lightCategoryColors, mediumCategoryColors, strongCategoryColors } from "../../../thematics/colors";
import { Thematic } from "../../../thematics/models/thematic.model";

@Component({
    selector: 'app-content-thematic',
    templateUrl: './content-thematic.component.html',
    styleUrls: ['./content-thematic.component.scss'],
})

export class ContentThematicCardComponent implements OnInit, OnChanges {
    
    public tags$ = new BehaviorSubject<Tag[]>([]);
    
    colorMedium!: string;

    colorStrong!: string;
  
    colorLight!: string;

    hovered = false;
    
    @Input() thematic!: Thematic;

    @Input() big = false;

    @Input() tiny = false;

    constructor(private readonly router: Router) {
        
    }

    ngOnInit() {
        this.colorMedium = this.thematic.category?.slug ? mediumCategoryColors[this.thematic.category.slug] : '';
        this.colorStrong = this.thematic.category?.slug ? strongCategoryColors[this.thematic.category.slug] : '';
        this.colorLight = this.thematic.category?.slug ? lightCategoryColors[this.thematic.category.slug] : ''; 
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thematic) {
          this.tags$.next(changes.thematic.currentValue.tags);
        }
    }

    onMouseOver(): void {
        this.hovered = true;
    }
    
    onMouseLeave(): void {
        this.hovered = false;
    }

    clickOnThematic(slug: string) {
        this.router.navigate([ 'thematics', slug ]);
      }
}