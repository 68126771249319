import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { extractVideoIdFromYoutubeUrl } from "../../../tool-functions/video";
import { IconType } from "../../icon/icon.component";

@Component({
    selector: 'app-media-card',
    templateUrl: './media-card.component.html',
    styleUrls: [ './media-card.component.scss' ]
})

export class MediaCardComponent {
    @Input() content: any;
    
    constructor(private sanitizer: DomSanitizer) { }

    get associatedThematics() {
        const thematicsTitles = this.content.thematics.map((thematic: any) => thematic.slug);
        return thematicsTitles.join('; ');
    }

    get remainingThematicslength() {
        return this.content.thematics.length - 2;
    }

    public youtubeUrl(url: string) {
        const link = url.includes('s3') ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${extractVideoIdFromYoutubeUrl(url)}`);
        return link;
    }

    get isVideo() {
        return ['video', 'podcast'].includes(this.content.format);
    }

    public getIcon(): IconType {
        if(this.content.format === 'pratical_sheet'){
            return 'pdf';
        }

        if(this.content.format === 'test'){
            return 'file';
        }

        return 'file';
    }

    getPillarIcon(): IconType {
        if(this.content.pillar === 'individual'){
            return 'candle';
        }

        if(this.content.pillar === 'environment'){
            return 'house-rustic';
        }

        if(this.content.pillar === 'event'){
            return 'storm';
        }

        return 'candle';
    }

    handleNoVideoClick() {
        window.open(this.content.resource, '_blank');
    }
}