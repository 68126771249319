import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { FilterInterface, FindResult, IPaginationRepository } from "../../shared/repositories/pagination.interface";
import { TrainMembership } from "../models/membership.entity";
import { CreateOrJoinTrain, CreateTrainPayload, JoinTrainPayload, sortTrains, Train } from "../models/train.entity";

export type TrainFilterForm = {
  startCreationDate: string;
  endCreationDate: string;
  startFirstSessionDate: string;
  endFirstSessionDate: string;
  membersCountMinimum: string;
  membersCountMaximum: string;
  isSpeech: string;
  isCoaching: string;
}

export type TrainPropertiesFilter = TrainFilterForm & {
  sherpaIds: string[],
}
export type TrainSessionUpdatePayload = {
  date: number;
  month: number;
  year: number;
  hours: number;
  minutes: number;
}

@Injectable({ providedIn: 'root' })
export class TrainRepository extends AbstractRepository<Train, CreateOrJoinTrain>
  implements IPaginationRepository<Train, TrainPropertiesFilter> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Train);
    this.apiUrl = this.appConfig.apiUrl;
  }

  override getEntityName(): string {
    return "trains";
  }

  override create(_: CreateTrainPayload): Observable<Train> {
    return of(new Train());
  }

  createTrain(trainPayload: CreateTrainPayload): Observable<TrainMembership> {
    return this.http.post<TrainMembership>([ this.apiUrl, this.getEntityName() ].join('/'), trainPayload)
      .pipe(map(memberShip => plainToInstance(TrainMembership, memberShip)));
  }

  joinTrain(trainPayload: JoinTrainPayload): Observable<TrainMembership> {
    return this.http.get<Train>([ this.apiUrl, this.getEntityName(), trainPayload.id, 'join' ].join('/'))
      .pipe(map(memberShip => plainToInstance(TrainMembership, memberShip)));
  }

  getMyTrains(): Observable<Train[]> {
    const lastConnectionDate = localStorage.getItem('lastConnectionDate');
    let url = [ this.apiUrl, this.getEntityName() ].join('/');
    if (lastConnectionDate) {
      url += `?lastConnectionDate=${encodeURIComponent(lastConnectionDate)}`;
    }
    return this.http.get<Train[]>(url)
      .pipe(map(trains => trains.map(train => this.instantiate(train)).sort(sortTrains)));
  }


  getAvailableTrains(): Observable<Train[]> {
    return this.http.get<Train[]>([ this.apiUrl, this.getEntityName(), 'available-trains' ].join('/'))
      .pipe(map(trains => trains.map(train => this.instantiate(train)).sort(sortTrains)));
  }

  getSherpaAvailableTrains(): Observable<Train[]> {
    return this.http.get<Train[]>([ this.apiUrl, this.getEntityName(), 'sherpa-available-trains' ].join('/'))
      .pipe(map(trains => trains.map(train => this.instantiate(train)).sort(sortTrains)));
  }

  manageTrain(trainId: string): Observable<void> {
    return this.http.get<void>([ this.apiUrl, this.getEntityName(), trainId, 'manage' ].join('/'));
  }

  leaveTrain(trainId: string): Observable<void> {
    return this.http.get<void>([ this.apiUrl, this.getEntityName(), trainId, 'leave' ].join('/'));
  }

  leaveTrainByMail(token: string): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.getEntityName(), 'leave-by-mail' ].join('/'), { token });
  }

  modifySherpa(trainId: string, sherpaId: string): Observable<void> {
    return this.http.patch<void>([ this.apiUrl, this.getEntityName(), trainId, 'sherpa' ].join('/'), { id: sherpaId });
  }

  paginate(filter: FilterInterface<TrainPropertiesFilter>): Observable<FindResult<Train>> {

    return this.http.post<FindResult<Train>>([ this.apiUrl, this.getEntityName(), 'paginate' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => this.instantiate(item))
      }))
    );
  }

  updateSessionDate(trainId: string, sessionId: string, datePayload: TrainSessionUpdatePayload): Observable<void> {
    return this.http.patch<void>([ this.apiUrl, this.getEntityName(), trainId, 'sessions', sessionId ].join('/'), datePayload);
  }

  getAll(): Observable<Train[]> {
    return this.http.get<Train[]>([ this.apiUrl, this.getEntityName(), 'all', 'export' ].join('/'))
      .pipe(map(items => items.map(item => this.instantiate(item))));
  }

  removeSherpa(trainId: string): Observable<void> {
    return this.http.get<void>([ this.apiUrl, this.getEntityName(), trainId, 'remove-sherpa' ].join('/'));
  }

  getTrainJoiningPermission(trainId: string): Observable<boolean> {
    return this.http.get<boolean>([ this.apiUrl, this.getEntityName(), trainId, 'can-join' ].join('/'));
  }
}
