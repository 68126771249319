import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { combineLatest, filter, startWith, Subject, switchMap, takeUntil, tap } from "rxjs";
import {  map } from "rxjs/operators";

import { AuthenticationService } from "../authentication/services/authentication.service";
import { AddFeedbackComponent } from '../feedbacks/add-feedback/add-feedback.component';
import { FeedbacksRepository } from '../feedbacks/repositories/feedbacks.repository';
import { MeetService } from "../meet/meet.service";
import { Organization } from "../organizations/models/organizations.entity";
import { OrganizationStoreService } from "../organizations/services/organization.store.service";
import { DialogService } from '../shared/components/dialog/dialog.service';
import { IconType } from "../shared/components/icon/icon.component";
import { StorageService } from "../shared/storage/storage.service";
import { capitalize } from "../shared/tool-functions/capitalize";
import { RoleColors } from '../shared/types/role-colors';
import { TrackerAction, TrackerCategory } from '../shared/types/tracker';
import { TypeformService } from "../typeform/typeform.service";
import { Talker } from "../users/models/talker.entity";
import { Role, RoleDescription } from "../users/models/users.entity";
import { Profile, ProfileService } from "../users/services/profile.service";


type TabName = {
  [role in Role]?: string;
} & {
  all: string;
};

type MenuTab = {
  id: string,
  name: TabName,
  icon: IconType,
  isAllowed(profile?: Profile): boolean,
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: [ './tabs.component.scss' ]
})
export class TabsComponent implements OnInit, OnDestroy {
  profile!: Profile;

  organizationSelected?: Organization;

  destroy$ = new Subject<void>();

  entering = false;

  rating: number | undefined;

  feedbackDate?: string;

  private readonly tracker = inject(MatomoTracker);

  protected readonly capitalize = capitalize;

  constructor(private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly authenticationService: AuthenticationService,
              private readonly storageService: StorageService,
              private readonly titleService: Title,
              private readonly meetService: MeetService,
              private readonly typeformService: TypeformService,
              private readonly dialog: DialogService,
              private readonly feedbacksRepository: FeedbacksRepository,
            ) {
  }

  private notPilotTabsList: MenuTab[] = [
    {
      id: 'home',
      name: { all: 'Accueil' },
      icon: 'house',
      isAllowed: () => true
    },
    {
      id: 'my-space',
      name: { all: 'Mon espace' },
      icon: 'heart',
      isAllowed: (profile: Profile) => profile.role === Role.Talker
    },
    {
      id: 'trains',
      name: { all: 'Parcours' },
      icon: 'calendar',
      isAllowed: (profile: Profile) => profile.role === Role.Admin
    },
    {
      id: 'my-trains',
      name: { all: 'Parcours' },
      icon: 'calendar',
      isAllowed: (profile: Profile) => profile.role === Role.Sherpa
    },
    {
      id: 'organizations',
      name: { all: 'Organisations' },
      icon: 'building',
      isAllowed: (profile: Profile) => profile.role === Role.Admin
    },
    {
      id: 'users',
      name: { all: 'Utilisateurs' },
      icon: 'users',
      isAllowed: (profile: Profile) => profile.role === Role.Admin
    },
    {
      id: 'thematics',
      name: { all: 'Thématiques' },
      icon: 'leaves',
      isAllowed: (profile: Profile) => profile.role !== Role.Sherpa
    },
    {
      id: 'thematics/my-thematics',
      name: { all: 'Thématiques' },
      icon: 'leaves',
      isAllowed: (profile: Profile) => profile.role === Role.Sherpa
    },
    {
      id: 'available-trains',
      name: { all: 'Parcours' },
      icon: 'calendar',
      isAllowed: (profile: Profile) => profile.role === Role.Talker
    },
    {
      id: 'statistics',
      name: { all: 'Statistiques' },
      icon: 'chart',
      isAllowed: (profile: Profile) => profile.role !== Role.Talker
    },
    {
      id: 'contents',
      name: { all: 'Contenu' },
      icon: 'play',
      isAllowed: (profile: Profile) => profile.role === Role.Talker
    },
    {
      id: 'questions',
      name: { all: 'Contact' },
      icon: 'bubble-smile',
      isAllowed: (profile: Profile) => profile.role !== Role.Admin
    }
  ];

  getPilotTabsList = (): MenuTab[] => ([
    {
      id: `organizations/${ this.organizationSelected!.id }`,
      name: { all: 'Accueil' },
      icon: 'house',
      isAllowed: () => true
    },
    {
      id: `organizations/${ this.organizationSelected!.id }/talkers`,
      name: { all: 'Collaborateurs' },
      icon: 'users',
      isAllowed: () => true
    },
    {
      id: `organizations/${this.organizationSelected!.id}/statistics`,
      name: { all: 'Satistiques' },
      icon: 'chart',
      isAllowed: () => true
    },
    {
      id: `organizations/${ this.organizationSelected!.id }/edit`,
      name: { all: 'Paramètres' },
      icon: 'parameters',
      isAllowed: () => true
    },
    {
      id: `organizations/${ this.organizationSelected!.id }/units`,
      name: { all: 'Crédits' },
      icon: 'crawn',
      isAllowed: () => true
    },
    {
      id: 'questions',
      name: { all: 'Contact' },
      icon: 'bubble-smile',
      isAllowed: () => true
    },
  ]);

  private tabsAllowed: MenuTab[] = [];

  reloadImage$ = new Subject<void>();

  tabSelected!: MenuTab;

  get tabs() {
    return this.tabsAllowed;
  }

  ngOnInit(): void {
    this.titleService.setTitle("We Talk");

    this.entering = true;
    combineLatest([
      this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd),
        map(routeEvent => routeEvent.url),
        startWith(this.router.url),
        map(url => url.split('?')[0]),
        map(url => url.split('/').slice(1).join('/'))
      ),
      this.profileService.getObservable(),
      this.organizationStoreService.getObservable()])
      .pipe(

        takeUntil(this.destroy$)
      )
      .subscribe(([ url, profile, organization ]) => {
        this.organizationSelected = profile?.role === Role.Admin ? undefined : organization;
        this.profile = profile ?? new Talker();
        this.tabsAllowed = this.organizationSelected ? this.getPilotTabsList() : this.notPilotTabsList.filter(tab => tab.isAllowed(profile));

        this.tabSelected = [ 'admins', 'sherpas', 'talkers' ].includes(url.split('/')[0])
          ? this.tabsAllowed.find(tab => tab.id === 'users') ?? this.tabSelected
          : [ ...this.tabsAllowed ].reverse().find(tab => url.search(tab.id) !== -1) ?? this.tabSelected;
        this.tabSelected ??= this.tabsAllowed[0];
      });

    this.profileService.imageUpdate$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.reloadImage$.next();
    });

    this.feedbacksRepository.getLatestFeedback().pipe(takeUntil(this.destroy$)).subscribe((feedback)=>{
      this.rating = feedback?.rating;
      this.feedbackDate = (new Date(feedback?.createdAt)).toLocaleDateString();
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clickOnLink(id: string): void {
    this.tabSelected = this.tabsAllowed.find(tab => tab.id === id) ?? this.tabsAllowed[0];
    this.tracker.trackEvent(TrackerCategory.MENU_TALKER, TrackerAction.CLICK, `${this.tabSelected.id}-menu`);
    this.router.navigate([ ...this.tabSelected.id.split('/') ]);
  }

  isTabSelected(tab: MenuTab): boolean {
    return this.tabSelected.id === tab.id;
  }

  logout(): void {
    if (!this.isImpersonating) {
      this.authenticationService.logout()
        .pipe(tap(() => this.router.navigate([ 'auth', 'login' ])))
        .subscribe(() => this.profileService.logout());
    } else {
      this.authenticationService.endImpersonate()
        .pipe(
          switchMap(() => this.profileService.loadProfile()),
          tap(() => this.router.navigate([ 'home' ])))
        .subscribe();
    }
  }

  goToProfile() {
    this.router.navigate([ 'my-profile' ]);
  }

  openFeedBackDialog() {
    this.dialog.open(AddFeedbackComponent, { rating: this.rating }).pipe(takeUntil(this.destroy$)).subscribe((feedback) => {
      if(feedback){
        this.rating = feedback.rating;
        this.feedbackDate = (new Date(feedback?.createdAt)).toLocaleDateString();
      }
    });
  }

  get isImpersonating() {
    return !!this.storageService.getAdminRefreshToken();
  }

  get role(): string {
    return capitalize(RoleDescription[this.profile.role]);
  }

  get isMeeting(): boolean {
    return this.meetService.isMeeting;
  }

  get isRespondingTypeform(): boolean {
    return this.typeformService.isResponding;
  }

  switchProfile() {
    this.router.navigate([ 'switch-profile' ]);
  }

  mapRatingValueToIconType(rating: number): {type: IconType, color: string} {
    switch (rating) {
        case 1: return { type: 'sad-emoji', color: "#ff4f4f" };
        case 2: return { type: 'woozy-emoji', color:"#FC9418" };
        case 3: return { type: 'neutral-emoji', color: "#FCC418" };
        case 4: return { type: 'happy-emoji', color: "#94D82C" };
        case 5: return { type: 'laughy-emoji', color: "#22C997" };
        default: return { type: 'happy-emoji', color: "#94D82C" };
    }
}

getColorByRole(role: Role): RoleColors {
  return RoleColors[role];
}

}
