import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";

import { ContentsRepository } from "../repositories/contents.repository";

@Component({
    selector: "app-contents-list",
    templateUrl: "./contents-list.component.html",
    styleUrls: ["./contents-list.component.scss"],
})

export class ContentsListComponent {
    items: any;

    contentsForm!: FormGroup;

    selectedItems: Set<string> = new Set();

    constructor(private readonly contentsRepository: ContentsRepository, private fb: FormBuilder) { }

    selectedTab: number = 1;

    objectives: string[] = ['inspire', 'prepare'];

    selectTab(tabIndex: number) {
      this.selectedTab = tabIndex;
      if(tabIndex === 1){
        this.objectives = ['inspire', 'prepare'];
      }

      if(tabIndex === 2){
        this.objectives = ['act'];
      }

        if(tabIndex === 3){
            this.objectives = ['transform'];
        }
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, ...this.contentsForm.value } }).subscribe((contents: any) => {
            this.items = contents.items;
        });
    }

    ngOnInit(): void {

        this.contentsForm = this.fb.group({
            formats: this.fb.array(this.createItems([{ key: 'video', label: 'Vidéo' }, { key: 'pratical_sheet', label: 'Fiche pratique' }, { key: 'podcast', label: 'Podcast' }, { key: 'infography', label: 'Infographie' } ])),
            durations: this.fb.array(this.createItems([{ key: 'below_five', label: '< 5 min' }, { key: 'between_five_fifteen', label: 'Entre 5 et 15 min'  }, { key: 'between_fifteen_thirty', label: 'Entre 15 et 30 min' }, { key: 'above_thirty', label: '> 30 min'  }])),
            themes: this.fb.array(this.createItems([{ key: 'meaning_life', label: 'Sens de la vie' }, { key: 'leadership', label: 'Leadership' }, { key: 'trust', label: 'Confiance' }, { key: 'mourning', label: 'Deuil' } ])),
          });

        this.subscribeToChanges();
          
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, ...this.contentsForm.value } }).subscribe((contents: any) => {
            this.items = contents.items;
        });
    }

    createItems(items: any[]): FormGroup[] {
        return items.map(item => this.fb.group({
          key: new FormControl(item.key),
          label: new FormControl(item.label),
          value: new FormControl(false)
        }));
      }

    private subscribeToChanges() {
        this.contentsForm.valueChanges.subscribe(value => {
          this.contentsRepository.findAll({ properties: { objectives : this.objectives, ...value } }).subscribe((contents: any) => {
            this.items = contents.items;
            });
        });
    }

    toggleSelection(item: string): void {
      if (this.selectedItems.has(item)) {
        this.selectedItems.delete(item);
      } else {
        this.selectedItems.add(item);
      }
    }
  
    isSelected(item: string): boolean {
      return this.selectedItems.has(item);
    }

    
      get formats(): FormArray {
        return this.contentsForm.get('formats') as FormArray;
      }
    
      get durations(): FormArray {
        return this.contentsForm.get('durations') as FormArray;
      }
    
      get themes(): FormArray {
        return this.contentsForm.get('themes') as FormArray;
      }
}