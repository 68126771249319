import { Type } from "class-transformer";

import { Organization } from "../../organizations/models/organizations.entity";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Lang } from "../../shared/types/lang";

export enum Gender {
  MAN = "man",
  WOMAN = "woman",
  OTHER = "other",
}

export const GenderDescription: Record<Gender, string> = {
  [Gender.MAN]: "Homme",
  [Gender.WOMAN]: "Femme",
  [Gender.OTHER]: "Autre",
};

export enum Role {
  Admin = "admin",
  Talker = "talker",
  Sherpa = "sherpa",
}

export type Pilot = "pilot";

export type SubRole = Role | Pilot;

export const RoleDescription: Record<SubRole, string> = {
  [Role.Admin]: "admin",
  pilot: "pilote",
  [Role.Talker]: "talker",
  [Role.Sherpa]: "sherpa",
};

export interface IUpdateUser {
  firstName: string;
  lastName: string;
  gender: Gender;
  phoneNumber?: string;
  description?: string;
  presentingVideoLink?: string;
}

export interface IUser extends IUpdateUser {
  id: string;
  email: string;
  lang: Lang;
  role: Role;
  activatedAt: Date;
  createdAt: Date;
  hasImage: boolean;
}

export class PersonalInformation {
  email!: string;

  firstName!: string;

  lastName!: string;

  gender!: Gender;

  phoneNumber?: string;

  description?: string;

  presentingVideoLink?: string;
}

export class User implements IUser {
  id!: string;

  imageUrl?: string;

  lastConnectionDateString?: string;

  @Type(() => PersonalInformation)
  personalInformation!: PersonalInformation;

  get email(): string {
    return this.personalInformation?.email ?? "";
  }

  set email(email: string) {
    this.personalInformation.email = email;
  }

  get firstName(): string {
    return this.personalInformation?.firstName ?? "Utilisateur supprimé";
  }

  set firstName(firstName: string) {
    this.personalInformation.firstName = firstName;
  }

  get lastName(): string {
    return this.personalInformation?.lastName ?? "";
  }

  set lastName(lastName: string) {
    this.personalInformation.lastName = lastName;
  }

  get phoneNumber(): string | undefined {
    return this.personalInformation?.phoneNumber;
  }

  set phoneNumber(phoneNumber: string | undefined) {
    this.personalInformation.phoneNumber = phoneNumber;
  }

  get description(): string | undefined {
    return this.personalInformation?.description;
  }

  set description(description: string | undefined) {
    this.personalInformation.description = description;
  }

  get isDeleted(): boolean {
    return !this.personalInformation;
  }

  get gender(): Gender {
    return this.personalInformation?.gender ?? Gender.OTHER;
  }

  set gender(gender: Gender) {
    this.personalInformation.gender = gender;
  }

  lang!: Lang;

  role!: Role;

  @Type(() => Date)
  activatedAt!: Date;

  @Type(() => Date)
  createdAt!: Date;

  hasImage!: boolean;

  get fullName(): string {
    return `${capitalize(this.firstName, { allWords: true })} ${capitalize(
      this.lastName,
      { allWords: true }
    )}`;
  }

  get civility(): string {
    return this.gender === Gender.MAN
      ? "Mr"
      : this.gender === Gender.WOMAN
      ? "Mme"
      : "";
  }

  get title(): string {
    return `${capitalize(this.firstName)} ${this.lastName
      .toUpperCase()
      .charAt(0)}.`;
  }
}

export type UserProfile = {
  firstName: string;
  lastName: string;
  description: string;
};

export type CreateUser = {
  email: string;
  gender: Gender;
  firstName: string;
  lastName: string;
  description?: string;
  lang?: Lang;
  role?: Role;
  phoneNumber?: string;
  password?: string;
};

export type CreateTalker = {
  email: string;
  gender: Gender;
  firstName: string;
  lastName: string;
};

export type CreateUserForm = {
  email: string;
  gender: string;
  firstName: string;
  lastName: string;
  role: Role;
  organization: string;
};

export type CreateUserOptions = CreateUser & {
  organization?: Organization;
  dynamicsAuthorized?: string[];
  isPilot?: boolean;
};

export type CanConsumeResult = {
  unitAvailable: boolean;
  quota: boolean;
  alreadyAskForMoreQuota: boolean;
  alreadyAskForMoreUnits: boolean;
};
