import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { allThematics } from "src/app/contents/models/contents.entity";

import { contentThematicCategoryTranslation } from "../../../../contents/translate";
import { mediumCategoryColors } from "../../../../thematics/colors";
import { ThematicCategorySlug } from "../../../../thematics/models/category.model";
import { extractVideoIdFromYoutubeUrl } from "../../../tool-functions/video";
import { IconType } from "../../icon/icon.component";


@Component({
    selector: 'app-media-card',
    templateUrl: './media-card.component.html',
    styleUrls: [ './media-card.component.scss' ]
})

export class MediaCardComponent {
    @Input() content: any;
    
    constructor(private sanitizer: DomSanitizer, private readonly router: Router ) { }

    get associatedThematics() {
        const thematicsTitles = this.content.thematics.map((thematic: any) => allThematics.find((t: any) => t.key === thematic.slug)?.label ?? '');
        return thematicsTitles.join('; ');
    }

    get remainingThematicslength() {
        return this.content.thematics.length - 2;
    }

    public youtubeUrl(url: string) {
        const link = url.includes('s3') ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${extractVideoIdFromYoutubeUrl(url)}`);
        return link;
    }

    get isVideo() {
        return ['video', 'podcast'].includes(this.content.format);
    }

    public getIcon(): IconType {
        if(this.content.format === 'pratical_sheet'){
            return 'pdf';
        }

        if(this.content.format === 'test'){
            return 'file';
        }

        return 'file';
    }

    getPillarIcon(pillar: any): IconType {
        if(pillar.name === 'individual'){
            return 'candle';
        }

        if(pillar.name === 'environment'){
            return 'house-rustic';
        }

        if(pillar.name === 'event'){
            return 'storm';
        }

        return 'candle';
    }

    handleNoVideoClick(event: any) {
        event.stopPropagation();
        window.open(this.content.resource, '_blank');
    }

    goToContent() {
        this.router.navigate([`/contents/${this.content.id}`]);
    }

    getCategoryNameBySlug(slug: ThematicCategorySlug): string {
        return contentThematicCategoryTranslation[slug] || '';
    }

    getColorBySlug(slug: ThematicCategorySlug): any {
        return mediumCategoryColors[slug] || '';
    }
}