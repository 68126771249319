import { Location } from '@angular/common';
import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, combineLatestWith, filter, from, Subject, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";

import { StorageService } from "../storage/storage.service";

import { NavigationService } from "./navigation.service";

@Injectable({ providedIn: 'root' })
export class FunnelService {
  // TODO: make page change animate work
  ANIMATION_DELAY = 0;

  nextComponentLoaded$ = new Subject<void>();

  pageHistory$ = new BehaviorSubject<string[][]>([]);

  goingToPreviousPage$ = new Subject<void>();

  goingToPreviousPage = false;

  private leavingFunnel$ = new Subject<void>();

  private enteringFunnel$ = new Subject<void>();

  resetScroll$ = new Subject<void>();

  animationEnd$ = new Subject<void>();

  previousPageMessage$ = new BehaviorSubject<string>('Retour');

  canValidate$ = new BehaviorSubject<boolean>(false);

  validateMessage$ = new BehaviorSubject<string>('Valider');

  validate$ = new Subject<void>();

  componentLoading$ = new BehaviorSubject<boolean>(false);

  backUrl$ = new BehaviorSubject<string>('');

  constructor(private readonly router: Router,
              private readonly navigationService: NavigationService,
              private readonly storageService: StorageService,
              private readonly location: Location) {

    this.pageHistory$.next(this.storageService.getPageHistory());


    // const enterInFunnelComponent$ =
    //   this.router.events.pipe(
    //     filter((event): event is NavigationStart => event instanceof NavigationStart),
    //     switchMap(() =>
    //       race(this.router.events.pipe(
    //         filter((event: any) => event.snapshot?.component?.name === 'FunnelComponent')
    //       ), this.router.events.pipe(
    //         filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    //         debounceTime(300),
    //       ))
    //     ),
    //     map((event) => event.hasOwnProperty('snapshot')));


    this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      combineLatestWith(
        this.router.events
          .pipe(
            filter(() => !this.goingToPreviousPage),
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event) => event.url)
          ),
        this.navigationService.lastPage$
      ),
      filter(([ _, currentPageUrl, lastPageUrl ]) => lastPageUrl !== currentPageUrl)
    )

      .subscribe(([ _, currentPageUrl, lastPageUrl ]) => {
        const isInFunnel = document.querySelector('app-funnel');
        this.canValidate$.next(false);
        this.previousPageMessage$.next('Retour');
        this.validateMessage$.next('Valider');
        this.resetScroll$.next();
        if (!isInFunnel) {
          this.pageHistory$.next([]);
        } else if (!this.goingToPreviousPage) {

          if (this.pageHistory$.value.length === 0) {
            this.pageHistory$.next([ lastPageUrl.split('/').map(e => e.split(/[?&]/)[0]).filter(e => e.length) ]);
          }
          const elementsToPush = currentPageUrl.split('/').map(e => e.split(/[?&]/)[0]).filter(e => e.length);
          const isSameOrSuperiorLength = this.pageHistory$.value.some((page) => page.length >= elementsToPush.length);
          if(!isSameOrSuperiorLength) {
            this.pageHistory$.next([ ...this.pageHistory$.value, currentPageUrl.split('/').map(e => e.split(/[?&]/)[0]).filter(e => e.length) ]);
          } else {
            const filteredPageHistory = this.pageHistory$.value.filter(page => page.length <= elementsToPush.length);
            const index = filteredPageHistory.findIndex((page) => page.length === elementsToPush.length);
            if (index !== -1) {
              filteredPageHistory[index] = elementsToPush;
            } else {
              filteredPageHistory.push(elementsToPush);
            }
            this.pageHistory$.next(filteredPageHistory);
          }
        }
      });


    this.pageHistory$
      .subscribe(pageHistory => {
        this.storageService.setPageHistory(pageHistory);
      });

    this.goingToPreviousPage$
      .pipe(
        tap(() => {
          this.goingToPreviousPage = true;
        }),
        switchMap(() => from(this.router.navigate(this.getPreviousPage() ?? ['/home']))))
      .subscribe(() => {
        const pageHistory = this.pageHistory$.value;
        const historyBefore = pageHistory.filter((_, index) => index !== pageHistory.length - 1);
        this.pageHistory$.next(historyBefore);
        this.goingToPreviousPage = false;
      });

    this.leavingFunnel$
      .pipe(
        tap(() => {
          this.goingToPreviousPage = true;
        }),
        switchMap(() => from(this.router.navigate(this.getFirstPage() ?? ['/home']))))
      .subscribe(() => {
        this.canValidate$.next(false);
        this.pageHistory$.next([]);
        this.animationEnd$.next();

        this.goingToPreviousPage = false;
      });

  }

  getPreviousPage(): string[] | undefined {
    return this.pageHistory$.value.length > 1 ? this.pageHistory$.value[this.pageHistory$.value.length - 2] : ['/home'];
  }

  getFirstPage(): string[] | undefined {
    return this.pageHistory$.value.length >= 1 ? this.pageHistory$.value[0] : undefined;
  }

  goToPreviousPage(): void {
    if(this.backUrl$.value.length) {
      this.router.navigateByUrl(this.backUrl$.value);  
    } else if (this.pageHistory$.value.length === 2) {
        this.leavingFunnel$.next();
    } else {
      this.goingToPreviousPage$.next();
    }
  }

  quitFunnel(): void {
    this.leavingFunnel$.next();
  }

  validate(): void {
    this.validate$.next();
  }

  updateBackUrl(url: string) {
    this.backUrl$.next(url);
  }



  //
  // navigate(nextPage: string[], previousPage: string[], resetScroll = false) {
  //   this.componentLoading$.next(true);
  //   this.canValidate$.next(false);
  //   this.validateMessage$.next('Valider');
  //   return from(this.router.navigate(nextPage))
  //     .pipe(
  //       map(() => ({
  //         enteredPage: nextPage,
  //         leftPage: previousPage,
  //         resetScroll
  //       } as PageChangeEvent)));
  // }

}
