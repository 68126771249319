import { Type } from "class-transformer";

import { ThematicCategory } from "../../thematics/models/category.model";
import { Thematic } from "../../thematics/models/thematic.model";

enum ObjectiveEnum {
    INSPIRE = 'inspire',
    PREPARE = 'prepare',
    TRANSFORM = 'transform',
    ACT = 'act',
}

enum FormatEnum {
    VIDEO = 'video',
    PODCAST = 'podcast',
    PRACTICAL_SHEET = 'pratical_sheet',
    TEST = 'test',
}

export enum ContentPillar {
    INDIVIDUAL = 'individual',
    ENVIRONMENT = 'environment',
    EVENT = 'event',
}

export enum ContentThematicCategory {
    PERSONAL_LIFE = 'personal-life',
    BETTER_LIFE = 'better-life',
    PROFESSIONAL_LIFE = 'professional-life',
    SELF_REFLECTION = 'self-reflection',

}

export const thematicsPartOne = [
        {
            key: 'meaning',
            label: 'Sens de la vie',
            selected: false
        },
        {
            key: 'leadership',
            label: 'Leadership',
            selected: false
        },
        {
            key: 'self-confidence',
            label: 'Confiance',
            selected: false
        },
        {
            key: 'mourning',
            label: 'Deuil',
            selected: false
        }
    ];

export const thematicsPartTwo = [
        {
            key: 'stress',
            label: 'Stress et émotions',
            selected: false
        },
        {
            key: 'parenthood',
            label: 'Parentalité',
            selected: false
        },
        {
            key: 'couple',
            label: 'Couple',
            selected: false
        },
        {
            key: 'burn-out',
            label: 'Burn out',
            selected: false
        }
    ];

export const thematicsPartThree = [
    {
        key: 'life-balance',
        label: 'Equilibre',
        selected: false
    },
    {
        key: 'relationships',
        label: 'Relations',
        selected: false
    },
    {
        key: 'time',
        label: 'Gestion du temps',
        selected: false
    },
    {
        key: 'Budget',
        label: 'Budget',
        selected: false
    }
];

export const thematicsPartFour = [
    {
        key: 'come-back',
        label: 'Reprise',
        selected: false
    },
    {
        key: 'Expatriation',
        label: 'Expatriation',
        selected: false
    },
    {
        key: 'remote',
        label: 'Manager en télétravail',
        selected: false
    },
    {
        key: 'handicap',
        label: 'Maladie et handicap',
        selected: false
    },
    {
        key: 'inclusion',
        label: 'Inclusion lgbtqia +',
        selected: false
    }
];

export const thematicsPartFive = [
    {
        key: 'harassment',
        label: 'Harcèlement',
        selected: false
    },
    {
        key: 'career-end',
        label: 'Fin de carrière',
        selected: false
    },
    {
        key: 'potential',
        label: 'Hauts potentiels',
        selected: false
    },
    {
        key: 'motherhood',
        label: 'Maternité',
        selected: false
    },
    {
        key: 'support',
        label: 'Accompagnement',
        selected: false
    }
];

export const allThematics =[
    ...thematicsPartOne, 
    ...thematicsPartTwo, 
    ...thematicsPartThree,
    ...thematicsPartFour,
    ...thematicsPartFive
];

export const contentFormats = [
    {
      key: 'video',
      label: 'Vidéo',
      selected: false
    }, 
    {
      key: 'pratical_sheet',
      label: 'Fiche pratique',
      selected: false
    }, 
    {
      key: 'podcast',
      label: 'Podcast',
      selected: false
    }, 
    {
      key: 'test',
      label: 'Infographie',
      selected: false
    }
  ];

export const pillars = [
    {
        key: 'individual',
        label: 'Individu',
        icon: 'candle',
        selected: false
    },
    {
        key: 'environment',
        label: 'Environnement',
        icon: 'house-rustic',
        selected: false
    },
    {
        key: 'event',
        label: 'Evénements',
        icon: 'storm',
        selected: false
    }
];


export const contentDurations = [
    { 
        key: 'below_five', 
        label: '< 5 min',
        selected: false 
    }, 
    { 
        key: 'between_five_fifteen', 
        label: 'Entre 5 et 15 min',
        selected: false  
    }, 
    { 
        key: 'between_fifteen_thirty', 
        label: 'Entre 15 et 30 min',
        selected: false 
    }, 
    { 
        key: 'above_thirty', 
        label: '> 30 min',
        selected: false  
    }
];
export interface IContent {
    id: string;
    title: string;
    description: string;
    image: string;
    resource: string;
    objective: ObjectiveEnum;
    format: FormatEnum;
    readingDuration: number;
    thematics: Thematic[];
    thematicCategories: ThematicCategory[];
}

export class Content implements IContent {
    id!: string;

    title!: string;

    description!: string;

    image!: string;

    resource!: string;

    objective!: ObjectiveEnum;

    pillars!: any [];

    format!: FormatEnum;

    readingDuration!: number;

    @Type(() => Thematic)
    thematics!: Thematic[];

    @Type(() => ThematicCategory)
    thematicCategories!: ThematicCategory[];

    @Type(() => Date)
    createdAt!: Date;
}