<div class="media-card margin-top" (click)="goToContent()">
    <div class="media">
      <img [src]="content.image" alt="Media Image">
      <div *ngIf="content.format === 'video' || content.format === 'podcast'" class="play-button-overlay">
        <app-icon type="play-contain" size="50" color="#FFFFFF" class="play-button"></app-icon>
      </div>
    </div>
    <div class="details">
      <h2 class="card-title">{{ content.title }}</h2>
      <div class="row gap">
        <div *ngFor="let pillar of content.pillars">
          <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
        </div>
        <div [ngStyle]="{'background-color': getColorBySlug(thematicCategory.slug) }" class="category-container" *ngFor="let thematicCategory of content.thematicCategories">
          {{ getCategoryNameBySlug(thematicCategory.slug) }}
        </div>
      </div>
      <div class="margin-top">
        <p class="description-container">
            {{ content.description }}
        </p>
      </div>
      <div *ngIf="content.thematics.length" class="margin-top padding-bottom thematic-container">
          Thématiques associées: {{associatedThematics}} 
          <span *ngIf="content.thematics.length > 2">; +{{ remainingThematicslength }}</span>
      </div>
    </div>
</div>