<div class="thematic-wrapper" [class.gray-filter]="thematic.isEnabled !== undefined && !thematic.isEnabled" (mouseenter)="onMouseOver()"
     (mouseleave)="onMouseLeave()" (click)="clickOnThematic(thematic.slug)">
    <div class="header">
        <div class="thematic-image" [class.big]="big">
          <app-image [height]="big ? 800 : 300" [width]="big ? 1200 : 450" [src]="thematic.imageUrl"
                         [alt]="thematic.slug" />
        </div>
        <div class="thematic-name-wrapper">
          <p class="thematic-name" [ngStyle]="{'background-color': colorMedium }">{{ thematic.name }}</p>
        </div>
    </div>
    <h3 class="thematic-title cooper"
      [ngStyle]="{'background-color': hovered ? colorLight : '' }">{{ thematic.title }}</h3>
</div>
