import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, Subject, takeUntil } from "rxjs";

import { MeetService } from "../../../meet/meet.service";
import { Role } from '../../../users/models/users.entity';
import { FunnelTrainService } from '../../services/funnel-train.storage.service';
import { FunnelService } from "../../services/funnel.service";

@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: [ './funnel.component.scss' ],
})
export class FunnelComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  previousMessage = 'ÉTAPE PRECEDENTE';

  validateMessage?: string;

  canValidate = false;

  showTakenTrainMessage = false;

  leaving = false;

  loading = true;

  isFullPage = false;

  isSherpa = false;

  isTrainAssignedSherpaOrAdminOrTalker = false;

  train: any = undefined;

  isTrainPage = false;

  backUrl = '';

  @ViewChild('funnel') elementRef!: ElementRef<HTMLElement>;

  constructor(public readonly funnelService: FunnelService,
              private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly meetService: MeetService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly funnelTrainService: FunnelTrainService
            ) {

  }

  get isMeeting(): boolean {
    return this.meetService.isMeeting;
  }


  ngOnDestroy() {
    this.leaving = true;
    this.destroy$.next();
    this.destroy$.complete();
    this.train = null;
    this.funnelService.updateBackUrl('');
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.isFullPage) {
          this.isFullPage = true;
        }
        if(data.backUrl){
          this.backUrl = data.backUrl;
          this.funnelService.updateBackUrl(data.backUrl);
        }
      });


    this.funnelService.componentLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoading => {
        this.loading = isLoading;
        this.changeDetector.detectChanges();
      });

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.destroy$))
      .subscribe(() => {
        this.previousMessage = 'Retour';
      });

    this.funnelService.previousPageMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        this.previousMessage = message;
        this.changeDetector.detectChanges();
      });

    this.funnelService.validateMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((validateMessage) => {
        this.validateMessage = validateMessage;
        this.changeDetector.detectChanges();
      });

    this.funnelService.canValidate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((canValidate) => {
        this.canValidate = canValidate;
        this.changeDetector.detectChanges();
      });

    this.funnelTrainService.showTakenTrainMessage$
    .pipe(takeUntil(this.destroy$))
    .subscribe((showTakenTrainMessage) => {
      this.showTakenTrainMessage = showTakenTrainMessage;
      this.changeDetector.detectChanges();
    });

    this.funnelService.resetScroll$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetScroll();
    });

    this.funnelTrainService.state$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isSherpa = this.funnelTrainService.state$.getValue()?.user?.role === Role.Sherpa;
      this.train = this.funnelTrainService.state$.getValue()?.train;
      this.isTrainAssignedSherpaOrAdminOrTalker = this.funnelTrainService.state$.getValue()?.train?.sherpaId === this.funnelTrainService.state$.getValue()?.user?.id || this.funnelTrainService.state$.getValue()?.user?.role === Role.Admin || this.funnelTrainService.state$.getValue()?.user?.role === Role.Talker;
      this.changeDetector.detectChanges();
    });

    const { url } = this.router;
    if(url.startsWith('/trains')){
      this.isTrainPage = true;
    }
  }

  async goToPreviousPage() {
    this.funnelService.canValidate$.next(false);
    this.funnelService.goToPreviousPage();
  }

  quitFunnel(): void {
    this.funnelService.canValidate$.next(false);
    this.funnelService.quitFunnel();
  }

  validate(): void {
    this.funnelService.validate();
  }

  resetScroll() {
    this.elementRef?.nativeElement?.scrollTo({ behavior: "auto", top: 0 });
  }

  accompanyGroup() {
    this.funnelTrainService.manageTrain(this.train.id);
  }

  goToTrains(){
    this.router.navigate(['/my-trains']);
  }

  goToThematics(){
    this.router.navigate(['/thematics']);
  }
}
