<div class="column gap">
    <h2 class="space-between row">{{content.title}}</h2>
    <div class="row gap center-children-vertically">
        <div *ngFor="let pillar of content.pillars">
            <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
        </div>
        <div [ngStyle]="{'background-color': getColorBySlug(category.slug) }" class="thematic-category-name-wrapper" *ngFor="let category of content.thematicCategories">
            {{ getCategoryNameBySlug(category.slug) }}
          </div>
    </div>
    <div class="details-wrapper row gap">
        <div class="content-details-main">
            <p class="content-details-description-text">
                {{content.description}}
            </p>
            <div class="row space-between margin-top margin-bottom-xl"> 
                <div class="row center-children-vertically" *ngIf="content.readingDuration">
                    <app-icon size="24" type="clock-circle"></app-icon>
                    <div class="duration-wrapper">{{content.readingDuration}} mn</div>
                </div>
                <div class="row">
                    <app-icon class="reaction-icon" size="24" [type]="liked === false ? 'thumb-dislike-active' : 'thumb-dislike-inactive'" (click)="handleDislikeReaction()"></app-icon>
                    <app-icon class="reaction-icon" size="24" [type]="liked === true ? 'thumb-like-active' : 'thumb-like-inactive'" (click)="handleLikeReaction()"></app-icon>
                </div>
            </div>
            <div *ngIf="content.format === 'pratical_sheet'" class="image-container center-horizontally-by-margin">
                <img [src]="content.image" alt="Description of image">
                <div (click)="downloadFile()" class="row download-button"><span>TÉLÉCHARGER</span><app-icon type="download" size="25"></app-icon></div>
            </div>
            <div class="column" *ngIf="content.format === 'podcast'">
                <img src="assets/images/animation-podcast.gif" alt="Loading animation" class="animation-gif">
                <app-audio-player [audioSrc]="content.resource"></app-audio-player>
            </div>

            <div class="video-wrapper" *ngIf="content.format === 'video'">
                <div class="video-container" *ngIf="!content.resource.includes('youtube')">
                    <video width="280" height="175" controls>
                        <source [src]="content.resource" type="video/mp4">
                        Your browser does not support the video tag.
                    </video> 
                </div>
                <div class="iframe-container" *ngIf="content.resource.includes('youtube')">
                    <iframe [src]="getSafeUrl(content.resource)" allowfullscreen></iframe>
                </div>
            </div>

        </div>
        <div class="divider"></div>
        <div class="column column-gap content-details-aside">
            <p class="margin-bottom">
                <strong>Approfondir ce sujet:</strong>
            </p>
            <p>
                Pour aller plus loin et être accompagné par nos Sherpas sur
                ce sujet, nous vous recommandons de rejoindre un parcours sur l'une 
                des thématiques suivantes: 
            </p>
            <div class="margin-top column gap" >
                <div *ngFor="let thematic of content.thematics">
                    <app-content-thematic class="margin-top-xl" [thematic]="thematic">
                        ></app-content-thematic>
                </div>
            </div>
        </div>
    </div>
</div>