import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { FunnelComponent } from "../shared/components/funnel/funnel.component";
import { SharedModule } from "../shared/shared.module";

import { ContentDetailsComponent } from "./content-details/content-details.component";
import { ContentThematicCardComponent } from "./content-details/content-thematic-card/content-thematic.component";
import { ContentsListComponent } from "./contents-list/contents-list.component";
import { ContentReactionsRepository } from "./repositories/content-reactions.repository";
import { ContentsRepository } from "./repositories/contents.repository";
import { ContentResolver } from "./resolvers/content.resolver";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild([
          {
                path: "",
                pathMatch: "full",
                component: ContentsListComponent,
                data: {
                  permissions: {
                    only: "admin",
                    redirectTo: "home",
                  },
                },
          },
          {
            path: ":id",
            pathMatch: "prefix",
            component: FunnelComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                component: ContentDetailsComponent,
                resolve: {
                  content: ContentResolver,
                }
              },
            ],
          }
      ])
    ],
    declarations: [
        ContentsListComponent,
        ContentDetailsComponent,
        ContentThematicCardComponent
    ],
    providers: [
        ContentsRepository,
        ContentResolver,
        ContentReactionsRepository,
    ]
})

export class ContentsModule { }